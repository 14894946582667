<template>
    <div>
        <v-row>
            <v-col class="d-none d-sm-flex">
                <v-toolbar-title>Mitarbeiter</v-toolbar-title>
            </v-col>
            <v-col>
                <v-text-field v-model="searchValue"
                              outlined
                              x-small
                              append-icon="mdi-magnify"
                              dense
                ></v-text-field>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.smAndUp" class="text-right">
                <v-btn @click="openEmployeeForm(null)" color="primary">HINZUFÜGEN</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                        :headers="headers"
                        :items="tableItems.data"
                        :server-items-length="tableItems.total"
                        :loading="loading"
                        class="elevation-1"
                        hide-default-footer
                >
                    <template v-slot:item.active="{ item }">
                        <v-chip
                                class="ma-2"
                                x-small
                                :color="item.active ? 'success' : 'accent'"
                        >
                            {{ item.active ? 'Ja' : 'Nein' }}
                        </v-chip>
                    </template>
                    <template v-slot:item.company_id="{ item }">
                        <div v-if="item.company">
                            <a @click.prevent="$root.$children[0].$refs['company-form'].open(item.company.id, true)"
                               href="#">{{ item.company.name }}</a>
                        </div>
                        <div v-else>-</div>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn @click="openEmployeeForm(item.id, true)" small icon>
                            <v-icon>
                                mdi-eye
                            </v-icon>
                        </v-btn>
                        <v-btn @click="openEmployeeForm(item.id)" small icon color="success">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                        <v-btn @click="deleteEmployee(item.id)" small icon color="accent">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import Paginate from '@/mixins/paginate'
    import {employeesPaginated, removeEmployee} from './../graphql/employee'

    export default {
        mixins: [Paginate],
        data() {
            return {
                loading: false,
                fetchQuery: employeesPaginated,
                modelName: 'employees',
                headers: [
                    {
                        text: 'Id',
                        sortable: false,
                        value: 'id',
                    },
                    {
                        text: 'Name',
                        sortable: false,
                        value: 'lastname',
                    },
                    {
                        text: 'Vorname',
                        sortable: false,
                        value: 'firstname',
                    },
                    {
                        text: 'Firmen',
                        sortable: false,
                        value: 'company_id',
                    },
                    {
                        text: 'Aktiv',
                        sortable: false,
                        value: 'active',
                    },
                    {
                        text: '',
                        sortable: false,
                        value: 'actions',
                        width: '120px'
                    }
                ],
            };
        },
        methods: {
            openEmployeeForm(id, readonly = false) {
                this.$root.$children[0].$refs['employee-form'].open(id, readonly, () => {
                    this.$apollo.queries.tableItems.refetch()
                })
            },
            deleteEmployee(id) {
                this.$root.$children[0].$refs.confirm.open('Delete', 'Sind Sie sicher das Sie entfernen möchten?', {color: 'accent'}).then((confirm) => {
                    if (confirm) {
                        this.$apollo.mutate({
                            mutation: removeEmployee,
                            variables: {id},
                            update: (store, {data: {removeEmployee}}) => {
                                this.tableItems.data = this.tableItems.data.filter(employee => {
                                    return removeEmployee.id != employee.id
                                })
                            }
                        })
                    }
                })
            },
        },
        mounted: function () {
            this.$root.$children[0].bottomMenu = [
                {
                    icon: 'add',
                    event: () => {
                        this.openEmployeeForm(null)
                    }
                }
            ]
        }
    }
</script>